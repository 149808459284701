import { Button, Col, Form, Input, Row, Select, Typography } from 'antd';
import React, { createContext, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import useCreateProduct from '../../hooks/useCreateProduct';
import useProfile from '../../hooks/useProfile';
import { countries } from '../../tools/contries';
import { paisesList } from '../../tools/paises';
import ModalPass from './components/ModalPass';
import ModalChangePass from './components/Modalvalidate';


const {Option} = Select;

const ProfileContext = createContext();
export default function MyData() {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const phone = Form.useWatch('phone', form);
    const {onChangeCategory, citys} = useCreateProduct();
    const [code, setCode] = useState('');
    const {
        user,
        modalPass,
        toggleModalpass,
        loading,
        onSaveData,
        modalCp,
        toggleModalChangepass,
        onChangePass
    } = useProfile()

    useEffect(() => {
        form.setFieldsValue(user)
        onChangeCategory(user.pais)
    }, []);

    const onChangeCode = useCallback((evt) => {
        form.setFieldsValue({phone: evt.target.value.replace(/\D|\-/,'')})
        // setCode(evt.target.value.replace(/\D|\-/,''));
    },[phone])

    return(
        <ProfileContext.Provider value={{
            modalPass,
            toggleModalpass,
            modalCp,
            loading,
            toggleModalChangepass,
            onChangePass
        }}>
        <div className='editdata_main'>
            <Typography.Title level={3}>{t('edit.title')}</Typography.Title>
            <Form form={form} layout='vertical' onFinish={onSaveData}>
                <Row gutter={[10, 0]}>
                    <Col xs={24} sm={24} md={24} lg={24}>
                        <Form.Item
                            name="name"
                            label={t('auth.create.name')}
                            rules={[
                                {required: true, message: t('input.required')},
                                {
                                    // required: true,
                                    min: 5,
                                    message: t('input.minletter')
                                },{
                                    pattern: /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/i,
                                    message: t('input.onlyletter')
                                }
                            ]}>
                            <Input placeholder={t('input.plsname')} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                label={t('auth.login.email')}
                name="email"
                rules={[{
                    required: true,
                    message: t('input.required')
                }, {
                    pattern: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
                    message: t('input.email')
                }]}>
                <Input type="email" placeholder="Example@example.com" />
            </Form.Item>
            </Col>
                    <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                label={t('edit.phone')}
                name="phone" rules={[{
                    required: true,
                    message: t('input.required')
                },
                {
                    max: 15,
                },
                {
                    pattern: /^[0-9]/,
                    message: t('edit.onlynumbers')
                }]}>
                <Input type='tel' 
                    value={code}
                    onChange={onChangeCode}
                    addonBefore={<Form.Item rules={[{
                    required: true,
                    message: t('input.required')
                }]} name="prefix" noStyle>
          <Select showSearch={true} style={{ width: 100 }}>
            {countries.map(p => <Option key={p.dial_code} value={p.dial_code}>{p.dial_code}</Option>)}
          </Select>
        </Form.Item>} placeholder={t('edit.plsphone')} />
            </Form.Item>


            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                label={t('auth.create.country')}
                name="pais" rules={[
                    {
                        required: true,
                        message: t('input.required')
                    }
                ]}>
                    <Select
                    showSearch={true}
                    onChange={onChangeCategory}
                    placeholder={t('auth.create.country')}
                    allowClear
                    >
                    {paisesList.map(p => 
                        <Option key={p.id} value={p.name}>{p.name}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                label={t('edit.city')}
                name="city" rules={[
                    {
                        required: true,
                        message: t('input.required')
                    }
                ]}>
                    <Select
                    showSearch={true}
                    placeholder={t('edit.cityp')}
                    allowClear
                    >
                    {citys.map(p => 
                        <Option key={p.id} value={p.name}>{p.name}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                label={t('edit.address')}
                  name="dir" rules={[
                    {
                        required: true,
                        message: t('input.required')
                    }
                ]}>
                  <Input
                    placeholder={t('edit.addressr')}/>
                </Form.Item>
              </Col>
              <Col lg={12}>
                <Form.Item>
                    <Button loading={loading} htmlType="submit" type="primary">{t('edit.save')}</Button>
                </Form.Item>
            </Col>
            <Col lg={12} style={{textAlign: 'right'}}>
                <Button onClick={toggleModalChangepass} type="link">{t('edit.change')}</Button>
            </Col>
                </Row>
            </Form>
        </div>
        <ModalPass />
        <ModalChangePass />
        </ProfileContext.Provider>
    );
}

export {ProfileContext}