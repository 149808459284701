import "../../styles/routes/profile.css";
import { Col, Menu, Row } from "antd";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useTranslation } from "react-i18next";
import { Route, Routes, useNavigate } from "react-router-dom";
import MenuApp from "../../components/Header";
import MyData from "./mydata";
import Publish from "./publish";
import CreateProduct from "./CreateProduct";
import Chat from "./chat";




export default function Profile({children}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const menuItems = [
    {key: '/publish', label: t("menu.publish"), onClick: () => navigate("/profile/publish")},
    {key: '/myuser', label: t("menu.edit"), onClick: () => navigate("/profile/myuser")},
    {key: '/chat', label: t("menu.chats"), onClick: () => navigate("/profile/chat")}
  ]

  useEffect(() => {
    const token = Cookies.get('token');
    if(!token) {
      navigate('/');
    }
}, [])

  return (
    <div className="customer_main">
      <Helmet>
        <title>Upgrap | Usuario</title>
      </Helmet>
      {/* <MenuP onKey={this.onKey} onSearch={this.onSearch} search={true} catalogo={false} /> */}
      <MenuApp />
      <Row className="row_customer">
        <Col xs={24} sm={24} md={4} lg={6}>
          <Menu
          style={{ width: "100%" }}
          defaultSelectedKeys={[window.location.href]}
          mode="inline"
          items={menuItems} />
        </Col>
        <Col xs={24} sm={24} md={20} lg={18}>
          <Routes>
            <Route path="/create" element={<CreateProduct />} />
            <Route path="/publish" element={<Publish />} />
            <Route path="/myuser" element={<MyData />} />
            <Route path="/chat/:id" element={<Chat />} />
            <Route path="/chat" element={<Chat />} />
          </Routes>
        </Col>
      </Row>
    </div>
  );
}

// import React, { Component } from 'react';
// import {  Menu, Row, Col } from 'antd';
// import MenuP from '../../components/Menu';
// import { Link, withRouter} from 'react-router-dom';

// import Cookies from 'js-cookie';
// import { Helmet } from 'react-helmet';
// import { Translation } from 'react-i18next';

// class Customer extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//           customers: [],
//           chatlist: [],
//           collapsed: false,
//           search: ''
//          }
//     }

//     async componentDidMount() {
//       const token = Cookies.get('token');
//       if(!token) {
//         this.props.history.push('/login');
//       }

//     }

//     onCollapse = collapsed => {
//         console.log(collapsed);
//         this.setState({ collapsed });
//       };

//       onSearch = (e) => {
//         this.setState({search: e.target.value});
//       }

//      onKey = (e) => {
//          if(e.key === "Enter") {
//              e.preventDefault();
//              e.stopPropagation();
//              this.props.history.push('/catalogo/s/'+this.state.search);
//          }
//      }

//     render() {
//         return (
//
//          );
//     }
// }

// export default Customer;
