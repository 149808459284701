import { useEffect, useState } from "react";

export default function useCronometer(seconds) {
    const [isActive, setIsActive] = useState(false)
    const [isPaused, setIsPaused] = useState(true);
    const [time, setTime] = useState(seconds);

    useEffect(() => {
        let interval = null;
        // activamos el contador
        if(isActive) {
            interval = setInterval(() => {
              setTime((time) => time-1)
            }, 1000)
        } else {
          clearInterval(interval)
        }
        return () => {
            clearInterval(interval)
          }

    }, [isActive, isPaused]);

    const Start = () => {
        setIsActive(true);
        setIsPaused(false);
    }

    const Pause = () => {
        setIsPaused(!isPaused);
    }

    const Reset = () => {
        setIsPaused(false);
        setTime(seconds)
    }

    return {
        time,
        Start,
        Pause,
        Reset
    }
}