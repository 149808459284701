import { useState } from 'react';
import {useTranslation} from 'react-i18next'
import {useNavigate} from 'react-router-dom';
import { get } from '../tools/http';

export default function useHome() {
    const {t} = useTranslation() // para obtener las alertas
    // const navigate = useNavigate(); // cambiar de vista
    const [categories, setCategories] = useState([]) // guardar categorias
    const [products, setProduct] = useState([]) // guardar productos

    const getCategories = async () => get('/category?all=true')
    .then(response => {
        console.log(response)
        setCategories(response)
    })
    .catch((err) => Promise.reject(err))

    const getProducts = async () => get('/product?limit=8')
    .then(response => setProduct(response.rows))
    .catch((err) => Promise.reject(err))

    return {
        getCategories,
        getProducts,
        categories,
        products
    }

}