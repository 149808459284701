import './App.css';
import {Routes, Route, Navigate} from 'react-router-dom'
import Home from './routes/home';
import Catalogo from './routes/products';
import Product from './routes/product';
import Profile from './routes/profile';
import { useAuth } from './container/authProvider';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import { getStorage } from './tools/storage';
import { USER } from './tools/config';
import ValidatePass from './container/change_pass';

const t = Cookies.get("token");

function App() {
  const {setAuth, setToken} = useAuth();

  useEffect(() => {
    if (t !== "") {
      setToken(t);
      setAuth(getStorage(USER))
    }
  }, [])

  return (
    <Routes>
      <Route path='/' element={<Navigate to='/home' />} />
      <Route path='/home' element={<Home />} />
      <Route path='/products/p/:page/:categorie' element={<Catalogo />} />
      <Route path='/products/s/:search/:page' element={<Catalogo />} />
      <Route path='/product/:id' element={<Product />} />
      <Route path='/profile/*' element={<Profile />} />
      <Route path='/profile' element={<Navigate to="/profile/publish" />} />
      <Route path='/recoover-pass/:code/:email' element={<ValidatePass />} />
    </Routes>
  );
}

export default App;
