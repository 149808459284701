import { Button, Checkbox, ConfigProvider, Empty, Form, Input, Select, Typography } from 'antd'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import { paisesList } from '../../tools/paises';
import { AuthContext, useAuth } from '../authProvider';

const {Text} = Typography;
const {Option} = Select;
export const requiredInput = [{required: true, message: "Este campo es obligatorio"}];

export default function FormRegister() {
    const {toggleModalTerm} = useContext(AuthContext);
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const password = Form.useWatch('password', form);
    const {onRegister, loading} = useAuth()
    // const {onRegister, loading} = useHome();

    return (
        <ConfigProvider renderEmpty={() => <Empty description={<span>{t('noresults')}</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
    <Form form={form} onFinish={(values) => onRegister(values)}>
        <Form.Item name="name" rules={[
                        {
                            required: true, 
                            message: t('input.required')},
                        {
                            // required: true,
                            min: 3,
                            message: t('input.minletter')
                        },{
                            pattern: /^[a-zA-ZÀ-ÖØ-öø-ÿ ]+$/i,
                            message: t('input.onlyletter')
                        }
                    ]}>
            <Input className='input_login' placeholder={t('auth.create.name')}  />
        </Form.Item>
        <Form.Item name="email" rules={[{
                        required: true,
                        message: t('input.required')
                    }, {
                        pattern: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
                        message: t('input.email')
                    }]}>
            <Input className='input_login' placeholder={t('auth.login.email')} />
        </Form.Item>
        <Form.Item name="pais" rules={[{required: true, message: t('input.required')}]}>
            <Select showSearch clearIcon className='select_login' placeholder={t('auth.create.country')}>
                {paisesList.map(p => <Option key={p.id} value={p.name}>{p.name}</Option>)}
            </Select>
        </Form.Item>
        <Form.Item name="password" rules={[{
                        required: true,
                        message: t('input.required')
                    },
                    {
                        min: 8,
                        message: t('input.minpass')
                    },
                    {
                        max: 16,
                        message: t('input.maxpass')
                    },
                    {
                        pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/,
                        message: t('input.pass')
                    }]}>
            <Input.Password className='input_login' placeholder={t('auth.login.password')} />
        </Form.Item>
        <Form.Item name="repass" rules={[{
                        required: true,
                        message: t('input.required')
                    },
                    {
                        min: 8,
                        message: t('input.minpass')
                    },
                    {
                        max: 16,
                        message: t('input.maxpass')
                    },
                    {
                        validator: (_, value) => password !== value ? Promise.reject(new Error(t('input.diffpass'))) : Promise.resolve(),
                        message: t('input.diffpass')
                    },
                    {
                        pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/i,
                        message: t('input.pass')
                    }]}>
            <Input.Password className='input_login' placeholder={t('auth.create.repass')} />
        </Form.Item>
        <Form.Item name="term" valuePropName="checked" rules={[{
                        validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error(t('input.term'))),
                    }]}>
            <Checkbox>{t('auth.create.i')} <Text className='text_term' onClick={toggleModalTerm}>{t('auth.create.term')}</Text></Checkbox>
            
        </Form.Item>
        <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary' className='btn_login'>{t('auth.login.create')}</Button>
        </Form.Item>
    </Form></ConfigProvider>)
}