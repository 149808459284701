import { Button, Col, ConfigProvider, Divider, Empty, Form, Input, Modal, Radio, Row, Select, Typography, Upload } from 'antd';
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import useCreateProduct from '../../hooks/useCreateProduct';
import useHome from '../../hooks/useHome';
import { paisesList } from '../../tools/paises';

const {Option} = Select;
const {Text} = Typography;

export default function CreateProduct() {
    const {t} = useTranslation();
    const {categories, getCategories} = useHome()
    const {
        citys, 
        onChangeCategory,
        fileList,
        previewImage,
        previewOpen,
        previewTitle,
        rulerOnlyLetter,
        onFinish,
        handleCancel,
        handleChange,
        handlePreview,
        uploadButton,
        loading
    } = useCreateProduct();

    useEffect(() => {
        getCategories()
    },[])

    return (
        <ConfigProvider renderEmpty={() => <Empty description={<span>{t('noresults')}</span>} image={Empty.PRESENTED_IMAGE_SIMPLE} />}>
        <div className='config-provider' style={{paddingLeft: '1em', paddingRight: '1em'}}>
        <Typography.Title level={3}>{t('creat.title')}</Typography.Title>
        <Divider />
        <Form requiredMark={false} onFinish={onFinish} layout="vertical">
        <Row gutter={[10, 0]}>
            <Col lg={24}>
                <Form.Item name="name" label={t('creat.namelabel')} rules={rulerOnlyLetter}>
                    <Input placeholder={t('creat.pname')} />
                </Form.Item>
            </Col>
            <Col lg={24}>
                <Form.Item name="description" label={t('product.description')} rules={rulerOnlyLetter}>
                    <Input.TextArea placeholder={t('creat.descriptionp')} />
                </Form.Item>
            </Col>
            <Col lg={12}>
                <Form.Item rules={rulerOnlyLetter} name="change" label={t('creat.changel')}>
                    <Input placeholder={t('creat.changep')} />
                </Form.Item>
            </Col>
            <Col lg={12}>
                <Form.Item rules={[{
                    required: true,
                    message: t('input.required')
                }]} name="category" label={t('product.category')}>
                    <Select placeholder={t('creat.categoryp')}>
                        {categories.map(item => <Option key={item.id} value={item.id}>{item.name}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                label={t('creat.countryp')}
                name="country" rules={[
                    {
                        required: true,
                        message: t('input.required')
                    }
                ]}>
                    <Select
                    showSearch={true}
                    onChange={onChangeCategory}
                    placeholder={t('auth.create.country')}
                    allowClear
                    >
                    {paisesList.map(p => 
                        <Option key={p.id} value={p.name}>{p.name}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                label={t('creat.cityp')}
                name="city" rules={[
                    {
                        required: true,
                        message: t('input.required')
                    }
                ]}>
                    <Select
                    showSearch={true}
                    placeholder={t('edit.cityp')}
                    allowClear
                    >
                    {citys.map(p => 
                        <Option key={p.id} value={p.name}>{p.name}</Option>)}
                    </Select>
                </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24}>
                <Form.Item
                label={t('edit.address')}
                  name="address" rules={[
                    {
                        required: true,
                        message: t('input.required')
                    }
                ]}>
                  <Input
                    placeholder={t('edit.addressr')}/>
                </Form.Item>
              </Col>
            <Col lg={24}>
                <Form.Item rules={[{
                    required: true,
                    message: t('creat.statel')
                }]} name="status" label={t('creat.productState')}>
                    <Radio.Group>
                        <Radio value={true}> {t('product.new')} </Radio>
                        <Radio value={false}> {t('product.used')} </Radio>
                    </Radio.Group>
                </Form.Item>
            </Col>
            <Col lg={24}>
                <Form.Item extra={t('alerts.max4images')} name="photo" label={t('creat.selectsomeimg')} rules={[
                    {
                        validator: (_, value) => value.fileList.length <= 0 ? Promise.reject(new Error(t('creat.selct1img'))) : Promise.resolve(),
                        message: t('creat.selct1img')
}]}>
                <Upload
                    multiple
                    beforeUpload={() => false}
                    listType="picture-card"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {fileList.length >= 4 ? null : uploadButton}
                </Upload>
            </Form.Item>
            </Col>
            <Col lg={24}>
                <Form.Item>
                    <Button loading={loading} htmlType="submit" type="primary">{t('creat.btn')}</Button>
                </Form.Item>
            </Col>
            </Row>
        </Form>
        <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
        <img
          alt="example"
          style={{
            width: '100%',
          }}
          src={previewImage}
        />
      </Modal>
    </div></ConfigProvider>);
}