import '../styles/components/productcard.css'
import { Card, Col, Image, Tag } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import notImage from "../assets/images/no-image.jpg";
import { useTranslation } from 'react-i18next';
import { httpApi } from '../tools/config';

export default function ProductCard({data}) {
    const {t} = useTranslation();
    return(
    <Col style={{marginTop: 8}} xs={24} sm={12} md={12} lg={8} xl={6}>
        <Link to={`/product/${data.id}`}>
        <Card className="card_product">
        <Tag className="tag_estado">{data.estado === 'nuevo' ? t('product.new') : t('product.used')}</Tag>
        <div className="container_img">
        <Image src={`${httpApi.baseUrl+httpApi.static+data.photo}`} preview={false} fallback={notImage} />
        </div>
        <div className='title_product_c'>
            <h4>{data.name}</h4>
        </div>
        <div className='description_container'>
            <p>{data.description.substring(0, 32)}</p>
        </div>
        <span style={{fontSize: 'small', fontWeight: 700}}>{`${data.pais ? data.pais+', ' : ''} ${data.city ? data.city : ''}`}</span> <br />
        {/* <Link to={`/product/${data.id}`}>{t('product.btn')}</Link> */}
        </Card>
        </Link>
    </Col>);
}