import axios from 'axios';
import { httpApi } from './config';

export const headerBearer = (token) => ({'Content-Type': 'application/json', "Authorization": `Bearer ${token}`})
export const headerFormData = (token) => ({'Content-Type': 'multipart/form-data', "Authorization": `Bearer ${token}`})

const instance = axios.create({
    baseURL: httpApi.baseUrl+httpApi.prefix,
    timeout: 15000,
  });

export const get = async (path, headers) =>
  await new Promise((resolve, reject) =>
    instance
      .get(path, { headers: headers && httpApi.headers })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response))
  );

export const post = async (path, data, headers) =>
  await new Promise((resolve, reject) =>
    instance
      .post(path, data, { headers: headers || httpApi.headers })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response))
  );

export const getId = async (path, id, headers) =>
  await new Promise((resolve, reject) =>
    instance
      .get(`${path}/${id}`, { headers: headers || httpApi.headers })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response))
  );

export const removeId = async (path, id, headers) =>
  await new Promise((resolve, reject) =>
    instance
      .delete(`${path}/${id}`, { headers: headers || httpApi.headers })
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response))
  );

export const jsonToMultipart = (data) => {
  try {
    const fd = new FormData();
    for (const key in data) {
      if (Object.hasOwnProperty.call(data, key)) {
          fd.append(key, data[key]);
      }
  }
  return fd;
  } catch (error) {
    console.log(error);
  }
} 

  export const UploadFile = async (url, data) => {
    return await new Promise((resolve, reject) => {
        fetch(httpApi.baseUrl+url, {
            method: 'POST',
            headers: {"Accept": "*/*"},
            body: data,
        }).then(res => res.json())
        .then(response => resolve(response))
        .catch(err => {
            console.log(err);
            reject(err.response);
        });
    })
}

export const update = async (url, data, id, option) => {
  return await new Promise((resolve, reject) => {
      axios.put(`${url}/${id}`, data, {headers: option}).then(res => {
          resolve(res.data);
      }).catch(err => reject(err.response));
  })
}
