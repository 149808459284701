import { Button, Card, Col, Image, Popconfirm, Row } from 'antd';
import {DeleteOutlined} from '@ant-design/icons'
import React from 'react'
import notImage from "../../../assets/images/no-image.jpg"
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { httpApi } from '../../../tools/config';

export default function ProductCustomer({data, onDelete}) {
    const {t} = useTranslation();
    return(<Card key={data.id} className="cardProduct">
    
    <Row>
   
        <Col  xs={22} sm={22} md={22} lg={22}>
            <Link to={`/product/${data.id}`}>
            <Image preview={false} fallback={notImage} src={httpApi.baseUrl+'/public/public/'+data.photo} width={80} />
            <div className="description_card">
                <p><strong>{t('product.name')}: </strong>{data.name} | {data.estado ? t('product.used') : t('product.new')} | {data.categoria}</p>
                <p><strong>{t('product.description')}: </strong>{data.description}</p>
            </div>
            </Link>
        </Col>
        
        <Col xs={2} sm={2} md={2} lg={2}>
            <Popconfirm
            onConfirm={onDelete} 
            okText={t('publish.yes')}
            cancelText={t('publish.no')}
            title={t('publish.pop')}>
                <Button type='link' icon={<DeleteOutlined />} />
            </Popconfirm>
        </Col>
    </Row>
    
</Card>);
}

