import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { USER } from "../tools/config";
import { get } from "../tools/http";
import { getStorage } from "../tools/storage";

export default function useProducts() {
    const navigate = useNavigate();
    const params = useParams();
    const [products, setProducts] = useState({rows: [], limit: 10, page: 1, total_rows: 0})
    const [search, setSearch] = useState("")
    const [category, setCategory] = useState(0);
    const [country, setCountry] = useState(() => {
        const user = getStorage(USER);
        return !user ? 'Perú' : user.pais;
    })

    const getProducts = async (category = 0, page = 1, country) => 
    get(`product?category=${category}&country=${country}&page=${page}`)
    .then(response => {
        console.log(response)
        setProducts(response);
    })
    .catch((err) => Promise.reject(err))

    const initProducts = (params) => {
        const page = params.page ? parseInt(params.page) : 1
        const cat = params.categorie ? parseInt(params.categorie) : 0;
        setCategory(cat);
        getProducts(cat, page, country)
    }

    const getProductsSearch = async (page = 1, search) => 
    get(`product?q=${search}&page=${page}`)
    .then(response => setProducts(response))
    .catch((err) => Promise.reject(err))

    const resetDom = () => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        setSearch("")
    }
    //* search
    const onSearch = (value) => setSearch(value.target.value)

    const onKey = (e) => {
        if(e.key === "Enter") {
            e.preventDefault();
            e.stopPropagation();
            
            if (search === null || search !== '' || search !== ' ') {
                navigate(`/products/s/${search}/1`)
                get(`products?page=1&q=${search}`)
                .then(response => setProducts(response))
                .catch((err) => Promise.reject(err))
            }
        }
    }

    const onClickSearch = (e) => {
        if (search === null || search !== '' || search !== ' ') {
            navigate(`/products/s/${search}/1`)
            get(`products?page=1&q=${search}`)
            .then(response => setProducts(response))
            .catch((err) => Promise.reject(err))
        }
    }


    const onchangeCountry = async (value) => {
        console.log(params)
        resetDom()
        
        setCountry(value);
        getProducts(parseInt(params.categorie) || 0, 1, value)
    }

    const onChangeCategory =  async (value) => {
        resetDom()
        setSearch('')
        setCategory(value);
        getProducts(value, 1, country)
    }

    const onChangePage = async (value) => {
        resetDom()
        if(search !== '' && search !== ' ') {
            getProductsSearch(value, search)
            return;
        }
        getProducts(category, value, country)
    }

    return {
        products,
        search,
        onKey,
        country,
        initProducts,
        onchangeCountry,
        onChangeCategory,
        category,
        onChangePage,
        onSearch,
        getProductsSearch,
        onClickSearch
    }
}