import {message as msg} from "antd"
import Cookies from "js-cookie";
import { useEffect, useRef, useState } from "react";
import { useAuth } from "../container/authProvider";
import { httpApi, USER } from "../tools/config";
import { get, headerBearer, post } from "../tools/http";
import { getStorage } from "../tools/storage";
// import { getStorage } from "../tools/storage";

// const socket = new WebSocket('ws://localhost:5000/api/v1/messages/ws');

// // Connection opened
// socket.addEventListener('open', (event) => {
//   socket.send('open socket');
// });



export default function useChat() {
  const [socket] = useState(new WebSocket(httpApi.urlWs))
  
  const {token} = useAuth();
  const [auth] = useState(getStorage(USER))
  const [chatList, setChatList] = useState([]);
  const [chatActive, setChatActive] = useState(null);
  const [messages, setMessages] = useState([]);
  const [customer, setCustomer] = useState(null);
  const [message, setMessage] = useState(null);
  const chat = useRef();

    useEffect(() => {
      
      // Listen for messages
      socket.addEventListener('message', ({data}) => {
        const res = JSON.parse(data)
        if(res.idReceiver === auth.id && chatActive === res.idSender) {
          // console.log("arr", messages)
          // console.log("add", messages.concat([res]))
          // console.log("add2", [res].concat(messages))
          setMessages(messages.concat([res]));
          chat.current.scrollTo({left: 0, top: chat.current.scrollHeight, behavior: 'smooth'})
        }
      });
    }, [messages])

    const initialize = async (id) => {
        console.log(auth)
        // console.log(timeAgo.format(1670415047000 + 4 * 1000 * 60 * 60))
        const token = Cookies.get('token');
        if(!token) {
          this.props.history.push('/login');
        } else {
          const chatlist = await get(`/chatlist?uid=${auth.id}&all=true`);
          let chatlist2 = chatlist.map(c => { return {...c, see: true}})
          
          setChatList(chatlist2)
          console.log(parseInt(id))
          console.log(chatlist.find(v => v.id === parseInt(id)))
          if(id) {
            handleClick(parseInt(id), chatlist.find(v => v.idUser === parseInt(id)))
          }
        //   this.setState({chatList: chatlist2, customer: user});
          // console.log({chatlist, customer: user});
        //   this.socket.on('room1', (data) => {
        //     const user = JSON.parse(window.localStorage.getItem('user'));
        //     if(data.id_receiver === parseInt(user.id) && data.id_sender === parseInt(this.state.chatActive)) {
        //       let arr = this.state.chats.concat([data])
        //       this.setState({chats: arr})
    
        //       // scroll
        //       setTimeout(() => { 
        //         let chat = this.chat.current;
        //         if(chat) chat.scrollTo({left: 0, top: chat.scrollHeight, behavior: 'smooth'})
        //       }, 100);
        //     }
        //     // let arr2 = chatlist2.map(c => data.chatlist === c.id ? {...c, see: true} : c);
        //     let chatlist2 = this.state.chatList;
        //     chatlist2.forEach((c, i) => {
        //       if(parseInt(c.id_user) === parseInt(data.id_sender)) {
        //         chatlist2[i].see = true
        //         console.log('ok')
        //       } else {
        //         console.log('err')
        //       }
        //     });
        //     // console.log(arr2);
        //     // let arr = this.state.chats.concat([data])
        //     this.setState({chatList: chatlist2})
        //     console.log(data)
        //   })
            // const {id} = this.props.match.params;
            // if(id) {
            //   this.handleClick(id);
            // }
        }
    }


    const handleClick = async (e, chat) => {
        console.log(e, auth.id)
        const response = await get(`/messages?sender=${auth.id}&receiver=${e}&all=true`, headerBearer(token));
        console.log(response)
        // const user = await get('/customer/',e, opt2());
        // console.log(messages.map(e => moment(e.fecha).unix()));
        // console.log(messages);

        setMessages(response);
        setChatActive(e)
        setCustomer(chat);
  
        // let message = messages[messages.length-1];
        // if(message && message.issee === false) {
        //   let arr = this.state.chatList.map(c => c.id === id ? {...c, see: false} : c)
        // this.setState({chats: messages, chatActive: e, user: user[0], chatList: arr, idchat: id})
        // } else {
        //   this.setState({chats: messages, chatActive: e, user: user[0], idchat: id})
        // } 
        setTimeout(() => {
          if(chat !== undefined) chat.current.scrollTo({left: 0, top: chat.current.scrollHeight})
        }, 500);
        
      }

      const sendMessage = async (file) => {
        try {
          const data = {
            message, 
            idSender: auth.id, 
            IdReceiver: chatActive, 
            issee: false, 
            type: "message",
            isFile: false,
          }
          const response = await post('/messages/ws', data, headerBearer(token));
          if(!response.success) throw response;
          setMessages(messages.concat([response.data]));
          setMessage("")
          chat.current.scrollTo({left: 0, top: chat.current.scrollHeight, behavior: 'smooth'})
          
        } catch (error) {
          console.log(error)
          msg.error("error al enviar mensage")
        }
      }

      const sendMessageWithKey = (event) => {
        if (event.key === 'Enter') {
          event.preventDefault();
          event.stopPropagation();
          sendMessage()
        }
    }

    const onChange = (e) => setMessage(e.target.value)
    return {
        auth,
        chatList,
        chatActive,
        handleClick,
        messages,
        // customer,
        message,
        sendMessage,
        sendMessageWithKey,
        initialize,
        chat,
        onChange,
        customer
    }
}