import { message } from 'antd';
import Cookies from 'js-cookie';
import React, { createContext, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { USER } from '../tools/config';
import { post } from '../tools/http';
import { setStorage } from '../tools/storage';
const AuthContext = createContext(null);

const AuthProvider = ({children}) => {
    const [auth, setAuth] = useState(null);
    const [token, setToken] = useState(null);
    
    const [open, setOpen] = useState(false)
    const [modal, setModal] = useState("login")
    const [term, setTerm] = useState(false);
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate();
    const {t} = useTranslation()


        //* toggle modal login
        const toggleModal = () => {
            setOpen(!open)
        }
    
        //* toggle modal login
        const toggleModalTerm = () => {
            setTerm(!term)
        }
    
        //* view modal
        const setModalView = (str) => {
            setModal(str)
        }

            //* ################
    //* register

    const onRegister = async (values) => {
        try {
            setLoading(true);
            const response = await post('/customer/register', {...values, singinMethod: 'email'})
            console.log(response)
            if(!response.success) {
                throw response;
            }
            Cookies.set('token', response.token, {expires: 60});
            setStorage(USER, response.data);
            setToken(response.token)
            setAuth(response.data)
            message.success(t('alerts.loginsocialsuccess'));
            setTimeout(() => {
              setLoading(false)
              toggleModal()
              navigate('/profile');
            }, 1000);
            
        } catch (err) {
            console.log(err)
        if(err.message === 'Usuario ya se encuentra registrado') {
            message.error(t('alerts.emailregister'), 9);
        } else {
            message.error(t('alerts.errorregister'));
        }
        setLoading(false)
    }
    
}

    //* ################
    //* login

    const onLogin = async (values) => {
        try {
            setLoading(true);
            const response = await post('/customer/login', values);
            if(!response.success) {
                throw response;
            }
            console.log(response);
            Cookies.set('token', response.token, {expires: 60});
            setStorage(USER, response.data);
            setTimeout(() => {
                setLoading(false);
                message.success(t('alerts.loginsuccess'));
                setAuth(response.data)
                setToken(response.token)
                toggleModal()
                navigate('/profile/myuser');
          }, 1000);
        } catch (err) {
            console.log(err);
            if(err.data.message === "Usuario o contraseña incorrectos") {
                message.error(t('alerts.errorup'));
            } else {
                message.error(t('alerts.errorlogin'));
            }
            setLoading(false);
        }
    }

    const onRecoverPass = async (values) => {
        try {
            setLoading(true);
            const response = await post('/customer/recover', {email: values.email});
            if(!response.success) throw response;
            message.success(t('alerts.sendemail'));
            setLoading(false);
        } catch (error) {
            setLoading(false);
            message.error(t('alerts.ersendmail'));
        }
      };
    

    return <AuthContext.Provider value={{
        auth, 
        setAuth, 
        token, 
        setToken,
        toggleModal,
        toggleModalTerm,
        setModalView,
        onRegister,
        loading,
        onLogin,
        onRecoverPass,
        modal,
        open
        }}>
        {children}
    </AuthContext.Provider>
}

const useAuth = () => {
    const context = useContext(AuthContext);
    if(context === undefined) {
        throw new Error("useAuthDispatch must be used within a AuthProvider");
    }

    return context;
}

export {AuthContext, useAuth};


export default AuthProvider;