import "../styles/routes/recover.css";
import { Button, Col, Form, Input, message, Row, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import MenuApp from '../components/Header';
import { post } from "../tools/http";

const {Title, Text} = Typography;

export default function ValidatePass() {
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const params = useParams();
    const navigate = useNavigate();
    const password = Form.useWatch('password', form);
    
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        const {code} = params;
        if(!code) {
            navigate("/")
        } 
    },[])

    const onFinish = async (values) => {
        
        try {
            setLoading(true);
            const response = await post('/customer/changepass', {
                email: params.email.trim(),
                password: values.password.trim(), 
                code: params.code.trim()
            });
            if(!response.success) throw response;
            message.success(t('alerts.updatedsuccess'));
            setLoading(false);
            navigate('/');
        } catch (error) {
            setLoading(false);
            message.error(t('lostpass.errcode'));
        }
      };
    // style={{background: `url(${bglogin})`}}
    return (<div className="login_main" >
    <Helmet>
      <title>Upgrap | {t('lostpass.title')}</title>
    </Helmet>
      {/* <Menu catalogo={false} search={false} /> */}
      <MenuApp />
      <div style={{width: '100%', marginTop: '5%'}}>
        <div className='recover_pass_div'>
            <Title level={3}>{t('lostpass.title')}</Title>
            <Text>{t('lostpass.text')}</Text>
        </div>
      <Row style={{width: '100%'}} justify="center">
          <Col xs={24} sm={24} md={12} lg={8}>
          <Form
          requiredMark={false}
          layout='vertical'
          form={form}
    name="basic"
    onFinish={onFinish}
  >
    <Form.Item
      name="password"
      label={t('edit.newpass')}
      rules={[{
        required: true,
        message: t('input.required')
    },
    {
        min: 8,
        message: t('input.minpass')
    },
    {
        max: 16,
        message: t('input.maxpass')
    },
    {
        pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/i,
        message: t('input.pass')
    }]}
    >
      <Input.Password placeholder={t('edit.newpass')} />
    </Form.Item>
    
    <Form.Item
      name="repassword"
      rules={[{
        required: true,
        message: t('input.required')
    },
    {
        min: 8,
        message: t('input.minpass')
    },
    {
        max: 16,
        message: t('input.maxpass')
    },
    {
        validator: (_, value) => password !== value ? Promise.reject(new Error(t('input.diffpass'))) : Promise.resolve(),
        message: t('input.diffpass')
    },
    {
        pattern: /^(?=\w*\d)(?=\w*[A-Z])(?=\w*[a-z])\S{8,16}$/i,
        message: t('input.pass')
    }]}
    label={t('auth.create.repass')}
    >
      <Input.Password  placeholder={t('auth.create.repass')} />
    </Form.Item>

    <Form.Item style={{marginTop: 20}}>
      <Button type="primary" loading={loading} htmlType="submit">
        {t('edit.save')}
      </Button>
    </Form.Item>
  </Form>
          </Col>
      </Row>
      </div>
  </div>);
}