import { useTranslation } from "react-i18next";
import { Col, Row, Grid, Typography } from "antd"
import logov from '../../assets/images/logov.svg';

const {useBreakpoint} = Grid;

const What = () => {
    const {t} = useTranslation();
    const screens = useBreakpoint();
    return (
        <div className="what_main">
            <Row justify="center">
                <Col xs={24} sm={24} md={12} lg={12}>
                <img width="100%" style={{padding: screens.md ? '13% 25%' : '10%'}} src={logov} alt="upgrap" />
                    
                </Col>
                <Col xs={24} sm={24} md={12} lg={12}>
                <Typography.Title level={3} style={{marginTop: '10%', marginBottom: 20}}>{t('home.what.h3')}</Typography.Title>
                    <Typography.Text>{t('home.what.p')}</Typography.Text>
                </Col>
            </Row>
        </div>
    )
}

export default What;