import { message } from "antd";
import Cookies from "js-cookie";
import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { USER } from "../tools/config";
import { getId, post } from "../tools/http";
import { getStorage } from "../tools/storage";
import TimeAgo from 'javascript-time-ago'
import es from 'javascript-time-ago/locale/es'
import en from 'javascript-time-ago/locale/en'
import i18next from 'i18next';

TimeAgo.addLocale(es)
TimeAgo.addLocale(en)

export default function useProduct() {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [user] = useState(getStorage(USER));
    const [token] = useState(Cookies.get('token'));
    const [loading, setLoading] = useState(false);
    const [timeAgo, setTimeAgo] = useState(new TimeAgo('en-EN'))

    useEffect(() => {
        if (i18next.language === 'es') {
            setTimeAgo(new TimeAgo('es-ES'))
        } else {
            setTimeAgo(new TimeAgo('en-EN'))
        }
    }, [i18next.language])

    const getProduct = async (id) => {
        try {
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
            
            const product = await getId('product', id);
            // console.log(moment(product.data.fecha).format())
            console.log(product.data)
            console.log(moment(product.data.fecha).format())
            setData(product.data);
        } catch (error) {
            message.error(t('alerts.errorget'))
        }
    } 

    const onToChat = async (data) => {
        if (!user && !token) return message.info(t('alerts.errortochat'));
        if (!user.validatePhone) return message.info(t('alerts.validatephone'));
        try {
            setLoading(true)
            const response = await post("/messages/chatlist", {
                "idSender": user.id,
                "idReceiver": data.idUser,
                "message": `${t('alerts.message')}: ${data.name}`,
                "issee": false,
                "type": "message",
                "isFile": false
            }, )
            if(!response.success) throw response;
            setLoading(false)
            navigate('/profile/chat/'+data.idUser);
        } catch (error) {
            message.error(t('alerts.errorsendmsg'))
            setLoading(false)
        }

    }

    const getFecha = (date) => {
        const fint = parseInt(date);
        console.log('date', fint)
        if(fint === undefined || fint < 1000000) {
            return timeAgo.format(moment(date).unix() * 1000)
        }
        return timeAgo.format(moment(fint).unix() * 1000)
    }

    return {
        data,
        getProduct,
        onToChat,
        loading,
        getFecha
    }

}