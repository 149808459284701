import '../../styles/routes/home.css'
import { Col, Row } from 'antd';
import Link from 'antd/es/typography/Link';
import React, { useEffect } from 'react'
import MenuApp from '../../components/Header';
import { useTranslation } from "react-i18next";
import ImagesApp from '../../components/Images';
import StepsPublish from './Steps';
import What from './What';
import useHome from '../../hooks/useHome';
import Categories from './categorias';
import Products from './Products';
import Footer from '../../components/Footer';
import { Helmet } from 'react-helmet';

let initial = false;
export default function Home() {
  const { t } = useTranslation();
  const {
    getCategories,
    getProducts,
    categories,
    products
  } = useHome();

  useEffect(() => {
    if (!initial) {
        getCategories()
            getProducts()
        initial = true
    }
    return () => {
        initial = false;
    }
}, [])

  return (
    <div className='home'>
                      <Helmet>
                    <title>Upgrap | Inicio</title>
                </Helmet>
      <MenuApp />
      <div className="cover">
        <Row style={{ width: "100%" }} align="middle" justify="space-between">
          <Col xs={24} sm={24} md={12} lg={12}>
            <h2>{t("home.portada.h2")}</h2>
            <h1>
              {t("home.portada.h1.1")}
              <span> {t("home.portada.h1.2")}</span>
            </h1>
            <h4>{t("home.portada.h4")}</h4>
            <Link to="/register">{t("home.portada.a")}</Link>
          </Col>
          <Col style={{ textAlign: "center" }} xs={24} sm={24} md={12} lg={12}>
            <ImagesApp name="home" width="80%" />
            {/* <ImgHome width="80%" /> */}
          </Col>
        </Row>
      </div>
      <div className='body_home'>
      <StepsPublish />
      <What />
      <Categories title={t('home.categories')} textBtn={t('home.btn_more')} data={categories} />
      {products.length > 0 && <Products title={t('home.last_products')} data={products} />}
      </div>
      <Footer />
    </div>
  );
}