import React, { createContext } from "react";
import useChat from "../hooks/useChat";

const ChatContext = createContext(null);

const ChatProvider = ({children}) => {
    const {
        auth, 
        chatList, 
        chatActive, 
        handleClick,
        messages,
        customer,
        message,
        sendMessage,
        sendMessageWithKey,
        initialize,
        chat,
        onChange
    } = useChat();

    return (<ChatContext.Provider value={{
        auth, 
        chatList, 
        chatActive, 
        handleClick,
        messages,
        customer,
        message,
        sendMessage,
        sendMessageWithKey,
        initialize,
        chat,
        onChange
    }}>
        {children}
    </ChatContext.Provider>)
}

export default ChatProvider;
export {
    ChatContext
}