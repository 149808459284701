import '../styles/components/menuapp.css';
import React from 'react';
import ImagesApp from './Images';
import SocialNetwork from './SocialNetwork';
import { Link, useNavigate } from "react-router-dom";
import { Button, Input } from 'antd';
import { useAuth } from '../container/authProvider';
import ModalAuth from '../container/modalAuth';
import { useTranslation } from 'react-i18next';
import { WechatOutlined } from '@ant-design/icons';
import Cookies from 'js-cookie';
import useProducts from '../hooks/useProducts';

export default function MenuApp({visible = true}) {
    const {toggleModal, setModalView, setAuth, setToken, token} = useAuth();
    const {search, onSearch, onKey, onClickSearch} = useProducts();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const logout = async () => {
        Cookies.remove('token');
        localStorage.removeItem('user')
        setAuth(null)
        setToken(null)
        navigate('/');
}


    return(<div className='menuapp'>
        <div className='menuapp_left'>
            <Link to="/"><ImagesApp name="letter" width={100} /></Link>
            <SocialNetwork />
        </div>
        <div className='seach_box'>
            <Link to='/products/p/1/0'><Button type='link'>{t('footer.menu.prod')}</Button></Link>
            {visible && <Input.Search 
            onKeyUp={onKey} 
            onSearch={onClickSearch} 
            value={search} onChange={onSearch} />}
        </div>
        {!token ? <div className='menuapp_right'>
            <Button onClick={() => {
                toggleModal();
                setModalView("login")
            }} type='link'>{t('footer.menu.login')}</Button> |
            <Button onClick={() => {
                toggleModal();
                setModalView("register")
            }} type='link'>{t('home.portada.a')}</Button>
        </div> : 
        <div>
            <Button onClick={() => navigate("/profile/publish")} type='primary'>{t('menu.my_acc')}</Button>
            <Button onClick={() => navigate("/profile/chat")}  type='link' icon={<WechatOutlined />} />
            <Button onClick={logout} type="link" >{t('menu.logout')}</Button>
        </div>}
        <ModalAuth />
    </div>);
}