import '../styles/components/footer.css';
import { Link } from 'react-router-dom';
import footer from '../assets/images/footer.svg';
import logo from '../assets/images/logob.svg';
// import tiktok from '../images/tiktok.svg';
// import fb from '../images/fb.svg';
// import instagram from '../images/instagram.svg';
// import twitter from '../images/twitter.svg';
import { Grid, Select, Typography } from 'antd'
import i18next from 'i18next';
import { useTranslation } from "react-i18next";
const {Option}=Select;
const {Text} = Typography;

const {useBreakpoint}= Grid;

const languages = [
    {code: 'en', name: 'English'},
    {code: 'es', name: 'Español'}
]
console.log(i18next.language)
const Footer = () => {
    const screens = useBreakpoint();
    const {t} = useTranslation();
    return(
        <footer style={{background: `url(${footer})`}} className="footer_">
            <img alt='upgrap logo' src={logo} width={screens.xs === true || (screens.sm === true && screens.md === false) ? '45%' : '15%'} />
            
            {/* <div style={{marginTop: 20}}>
                <a className="a_footer" target="blank" href="https://m.facebook.com/upgrap/">
                    <img src={fb} />
                </a>
                <a className="a_footer" target="blank" href="https://www.instagram.com/upgrap/?igshid=qhltjrcnc4zy">
                    <img src={instagram} />
                </a>
                <a className="a_footer" target="blank" href="https://twitter.com/upgrap_">
                    <img src={twitter} />
                </a>
                <a className="a_footer" target="blank" href="https://vm.tiktok.com/ZMJ7T7Xbm/">
                    <img src={tiktok} />
                </a>
            </div> */}
            <Text style={{display: 'block', color: "#fff", marginTop: 5, marginBottom: 0, paddingBottom: 0}}><strong>{t('footer.contanc')}:</strong> <a  style={{color: "#fff"}} href="mailto:support@upgrap.com">support@upgrap.com</a></Text>
            {/* <p ><strong>{t('footer.contanc')}:</strong> <a  style={{color: "#fff"}} href="mailto:support@upgrap.com">support@upgrap.com</a></p> */}
            <div>
                <Link to="/catalogo"><Text>{t('footer.menu.prod')}</Text></Link> <span> | </span>
                <Link to="/login"><Text>{t('footer.menu.login')}</Text></Link> <span> | </span>
                <Link to="/register"><Text>{t('home.portada.a')}</Text></Link> <span> | </span>
                <Link to="/terms"><Text>{t('auth.create.term')}</Text></Link>
                <Select value={i18next.language} onChange={(value) => {
                    window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
                    i18next.changeLanguage(value)
                }} style={{ width: 120 }} bordered={false}>
                    {languages.map(({code, name}) => 
                    <Option key={code} value={code}>{name}</Option>)}
                </Select>
            </div>
            <Text>{t('footer.copy')}</Text>
        </footer>
    )
}

export default Footer;