import { message } from "antd";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../container/authProvider";
import { USER } from "../tools/config";
import { get, headerBearer, post, removeId } from "../tools/http";
import { getStorage, setStorage } from "../tools/storage";
import useCronometer from "./useCount";

const timer = 30;

export default function usePublish() {
    const {token} = useAuth();
    const {time, Reset, Start} = useCronometer(timer);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const [products, setProducts] = useState([]);
    const [user, setUser] = useState(getStorage(USER));
    const [open, setOpen] = useState(false);
    const [code, setCode] = useState('');

    const getMyProducts = async () => {
        try {
            const response = await get(`/product?customer=${user.id}`);
            console.log(response)
            setProducts(response.data);
        } catch (error) {
            message.error(t('alerts.errmyprod'));
        }
    }

    const openModal = () => {
        setOpen(true);
        if(time <= 0 || time === timer ) {
            
            reSendOTP()
        }
    };

    const createPublish = async () => {
        if(!user.phone || !user.prefix) {
            message.info(t('alerts.notphone'));
        } else if(!user.validatePhone) {
            try {
                openModal();
            } catch (error) {
                message.error(t('alerts.errsms'))
            }
        } else {
            navigate('/profile/create');
        }
    }

    const reSendOTP = async () => {
        const data = {phone: user.prefix+user.phone, idUser: user.id, createAt: moment().format(), expireAt: moment().add(1, 'hour').format()}
        try {
            Reset();
            Start();
            await post('/customer/send', data, headerBearer(token))
            message.success(t('alerts.sendsms'));
        } catch (error) {
            message.error(t('alerts.errsms'))
        }
    }

    const sendCode = async () => {
        try {
            const response = await post('/customer/validate', {code, idUser: user.id}, headerBearer(token));
            if(response) {
                const r = {...user, validatePhone: true};
                setStorage(USER, {...r});
                setUser({...r})
                message.success(t('alerts.validatesms'))
                navigate('/profile/create')
            } else {
                message.error(t('alerts.errvalidatesms'))
            }
        } catch (error) {
            message.error(t('alerts.err400'))
        }
    
    }

    const handleMenuClick = async (id) => {
        await removeId('/product', id, headerBearer(token)).then(() => {
            message.success(t('alerts.deleteproduct'));
            // console.log(res, key)
            setProducts(products.filter(p => p.id !== id));
        }).catch(err => message.error(t('alerts.errdelete')))
    }

    const closeModal = () => setOpen(false);

    const onChangeCode = (evt) => setCode(evt.target.value.replace(/\D|\-/,''))

    return {
        getMyProducts,
        products,
        time,
        open,
        closeModal,
        createPublish,
        handleMenuClick,
        user,
        code,
        reSendOTP,
        onChangeCode,
        sendCode
    }
    
}