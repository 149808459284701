import '../../styles/routes/products.css';
import { Col, Menu, Pagination, Row, Select, Typography } from 'antd';
import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import { useNavigate, useParams } from 'react-router-dom';
// import MenuP from '../../components/Menu';
// import ProductCard from '../../components/ProductCard';
import useHome from '../../hooks/useHome';
import useProducts from '../../hooks/useProducts';
import i18next, { t } from 'i18next';
import MenuApp from '../../components/Header';
import ProductCard from '../../components/ProductCard';
import { paisesList } from '../../tools/paises';

const {Option} = Select;
const {Title} = Typography;

export default function Catalogo() {
    const navigate = useNavigate();
    const params = useParams()
    const {categories, getCategories} = useHome()
    const {
        onSearch,
        search,
        onKey,
        country,
        products,
        initProducts,
        onchangeCountry,
        onChangeCategory,
        onChangePage,
        category,
        getProductsSearch
    } = useProducts()


    useEffect(() => {
        console.log(params)
        getCategories()
        if(!params.search) {
            console.log(products)
            initProducts(params)
        } else {
            // onSearch(params.search)
            getProductsSearch(1, params.search)
        }
    }, [params])

    return (<div className='catalogo_container'>
        <Helmet><title>Upgrap | Productos</title></Helmet>
        {/* <MenuP search={false} valueSearch={search} onKey={onKey} catalogo={true} onChange={onSearch} /> */}
        <MenuApp />
        <Row>
            <Col xs={24} sm={24} md={6} lg={6}>
                <div className='sidebar_products'>
                    <Select clearIcon showSearch onChange={onchangeCountry} value={country} className='select_country'>
                    {paisesList.map(p => (
                        <Option key={p.id} value={p.name}>{p.name}</Option>
                    ))}
                    </Select>
                    <Menu activeKey={0} items={
                        [{
                            key: 0,
                            label: "Todos",
                            onClick: () => {
                                navigate(`/products/p/1/0`);
                                initProducts(params)
                            }
                        }].concat(categories.map((item) => {
                            return {
                                key: item.id,
                                label: i18next.language === 'en' ? item.namei : item.name,
                                onClick: () => {
                                    onChangeCategory(item.id)
                                    navigate(`/products/p/1/${item.id}`)
                                   
                                }
                            }
                        }))} />
                </div>
            </Col>
            <Col xs={24} sm={24} md={18} lg={18}>
                {products.rows.length === 0 ? 
                <Title type='secondary' style={{textAlign: 'center', marginTop: '2em'}} level={3}>{t('product.notproducts')}</Title> : 
                <Row className='products_container' gutter={[10, 0]}>
                    {products.rows.map(item => <ProductCard key={item.id} data={item} />)}
                </Row>
                    }
                            <div style={{ width: '100%', textAlign: "center" }}>
                  {products.rows.length > 0 && <Pagination 
                  style={{padding: '60px 0'}} 
                  onChange={(value) => {
                    if(params.search) {
                        // console.log(`/catalogo/s/${params.search}/${value}`)
                        // history.push(`/catalogo/s${params.search}/${value}`)
                        getProductsSearch(value, params.search)
                        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
                        // onChangePage(value);
                    } else {
                        navigate(`/products/p/${value}/${category}`)
                        onChangePage(value);
                    }
                  }} 
                  pageSize={products.limit} 
                  current={products.page} 
                  total={products.total_rows} />}
                </div>
            </Col>
        </Row>

    </div>);
}