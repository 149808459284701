import { Button, Form, Input } from 'antd'
import React from 'react'
import { useTranslation } from 'react-i18next';
import { useAuth } from '../authProvider';

export default function FormRecoverPass() {
    const {loading, onRecoverPass} = useAuth();
    const {t} = useTranslation()
    // const {} = useContext()
    return (<Form onFinish={onRecoverPass}>
        <Form.Item name="email" rules={[{
                        required: true,
                        message: t('input.required')
                    }, {
                        pattern: /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i,
                        message: t('input.email')
                    }]}>
            <Input className='input_login' placeholder={t('auth.login.email')} />
        </Form.Item>
        <Form.Item>
            <Button htmlType='submit' loading={loading} type='primary' className='btn_login'>{t('auth.recooverpass.title')}</Button>
        </Form.Item>
    </Form>)
}