import React from 'react'
import letter from '../assets/images/letter.svg'; 
import home from '../assets/images/home.svg'; // logo del header

//! logos
// letter

const images = {
    'letter': letter,
    'home': home
}

const ImagesApp = ({name, width}) => 
(<img width={width} src={images[name]} alt={`upgrap - ${name}`} />);

export default ImagesApp