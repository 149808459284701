import { useTranslation } from "react-i18next";
import { Col, Row, Grid } from "antd"
const {useBreakpoint} = Grid;


const StepsPublish = () => {
    const screens = useBreakpoint();
    const {t} = useTranslation();
    return (
        <div className="steps_main">
            <h1>{t('home.steps.h1')}</h1>
            {screens.md === true ?             <Row>
                <Col style={{borderRight: '1px solid #ccc'}} xs={24} sm={24} md={8} lg={8}>
                    <h2>{t('home.portada.a')}</h2>
                    <p>{t('home.steps.1.p')}</p>
                </Col>
                <Col style={{borderRight: '1px solid #ccc'}} xs={24} sm={24} md={8} lg={8}>
                    <h2>{t('home.steps.2.h2')}</h2>
                    <p>{t('home.steps.2.p')}</p>
                </Col>
                <Col xs={24} sm={24} md={8} lg={8}>
                    <h2>{t('home.steps.3.h2')}</h2>
                    <p>{t('home.steps.3.p')}</p>
                </Col>
            </Row> : 
                        <Row>
                        <Col style={{marginTop: 20, borderBottom: '1px solid #ccc'}} xs={24} sm={24} md={8} lg={8}>
                            <h2>{t('home.portada.a')}</h2>
                            <p>{t('home.steps.1.p')}</p>
                        </Col>
                        <Col style={{marginTop: 20, borderBottom: '1px solid #ccc'}} xs={24} sm={24} md={8} lg={8}>
                            <h2>{t('home.steps.2.h2')}</h2>
                            <p>{t('home.steps.2.p')}</p>
                        </Col>
                        <Col style={{marginTop: 20}} xs={24} sm={24} md={8} lg={8}>
                            <h2>{t('home.steps.3.h2')}</h2>
                            <p>{t('home.steps.3.p')}</p>
                        </Col>
                    </Row>}

        </div>
    )
}

export default StepsPublish;