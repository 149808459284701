import { Button, Card, Col, Row, Typography } from "antd";
import { Link } from "react-router-dom";
import i18next from 'i18next';
import { httpApi } from "../../tools/config";
const Categories = ({data, title, textBtn}) => {
  // const currentLanguageCode = Cookies.get('i18next') || 'en';

    return (
      <div className="categorias_main">
        <Link to="/products"><Typography.Title level={2}>{title}</Typography.Title></Link>
        <Row>
          {data.map((d) => (
            <Col key={d.id} xs={24} sm={24} md={12} lg={6}>
              <Link to={`/products/p/1/${d.id}`}>
                <Card>
                    <img src={`${httpApi.baseUrl}/public/public/categories/${d.image}`} alt={d.name} />
                     <h5>{i18next.language === 'en' ? d.namei : d.name}</h5>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
        <div style={{width: '100%', textAlign: 'right', marginTop: 20}}>
          
          <Link to="/products/p/1/0"><Button type="primary">{textBtn}</Button></Link>
        </div>
      </div>
    );
}

export default Categories;