import { Button, Card, Col, Image, Input, Menu, Row } from 'antd'
import {UserOutlined, SendOutlined } from '@ant-design/icons';
import React, { useContext, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import TimeAgo from 'javascript-time-ago'
import moment from 'moment/moment';
import { ChatContext } from '../../container/ChatProvider';
import { useParams } from 'react-router-dom';
// import es from 'javascript-time-ago/locale/es'

// TimeAgo.addLocale(es)
const timeAgo = new TimeAgo('es-ES')

export default function Chat() {
  const params = useParams();
    const {
        auth, 
        chatList, 
        chatActive, 
        handleClick,
        messages,
        message,
        sendMessage,
        sendMessageWithKey,
        initialize,
        chat,
        customer,
        onChange
    } = useContext(ChatContext);

    useEffect(() => {
      fetchData()
    }, [params])

    const fetchData = async () => {
      console.log(customer)
      await initialize(params.id)
    }

    return (
      <div className="chat_main">
        <Helmet>
          <title>Upgrap | Chat</title>
        </Helmet>
        {/* <MenuP onKey={this.onKey} onSearch={this.onSearch} search={true} catalogo={false} /> */}

        {/* <Button style={{marginTop: 30}} type="link" icon={<ArrowLeftOutlined />}>
      <Link to="/customer">Volver</Link>
    </Button> */}
        <Card
          bordered={false}
          title={
            customer !== null &&
            `${customer.name} - ✆ ${customer.prefix !== null ? customer.prefix : ""}${
              !customer.phone ? "" : customer.phone
            }`
          }
          className="chat_card"
        >
          <Row>
            {/* chat list */}
            <Col xs={24} sm={24} md={6} lg={6}>
              <Menu
                style={{ width: "100%", minHeight: "60vh" }}
                defaultSelectedKeys={[chatActive]}
                defaultOpenKeys={["sub1"]}
                mode="inline"
                items={chatList.length <= 0 ? [] : 
                    chatList.map(c => ({
                        key: c.id, 
                        icon: <UserOutlined />, 
                        label: c.name,
                        onClick: () => handleClick(c.idUser, c)
                    }))}
              />
                {/* <Menu.ItemGroup key="sub1" title="Chats">
                  {chatList.length > 0 &&
                    chatList.map((c) => (
                      <Menu.Item
                        onClick={() => handleClick(c.idchat, c.id)}
                        icon={<UserOutlined />}
                        key={c.idchat}
                      >
                        <Badge dot={c.see}>{`${c.name}`}</Badge>
                      </Menu.Item>
                    ))}
                </Menu.ItemGroup> */}
              {/* </Menu> */}
            </Col>

            <Col className="col_chat" xs={24} sm={24} md={18} lg={18}>
              {messages.length > 0 && (
                <div ref={chat} id="chats_zone" className="zone_messages">
                  {messages.map((m) =>
                    m.idSender !== auth.id ? (
                      <div key={m.id} className="container_left">
                        {m.image ? (
                          <Image src={m.image} width={200} />
                        ) : (
                          <div>
                            <p className="mesageTag">{m.message}</p> <br />
                            <span style={{ fontSize: "small" }}>
                              {timeAgo.format(
                                moment(m.fecha).unix() * 1000 +
                                  +4 * 1000 * 60 * 60
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    ) : (
                      <div key={m.id} className="container_right">
                        {m.image ? (
                          <div className="imgchat">
                            <Image src={m.image} width={200} />
                          </div>
                        ) : (
                          <div>
                            <p className="messageRight">{m.message}</p> <br />
                            <span style={{ fontSize: "small" }}>
                              {timeAgo.format(
                                moment(m.fecha).unix() * 1000 +
                                  4 * 1000 * 60 * 60
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    )
                  )}
                </div>
              )}
              {chatActive !== null && (
                <div className="zone_input">
                  <Input
                    onKeyDown={sendMessageWithKey}
                    style={{
                      width: "100%",
                      marginRight: 10,
                      marginLeft: 10,
                    }}
                    value={message}
                    onChange={onChange}
                  />
                  {/* <label htmlFor="file">
                <Button
                  onClick={this.clickinputFile}
                  type="link"
                  style={{ marginRight: 10 }}
                  icon={<CameraOutlined />}
                />
                <input
                  onChange={this.uploadImage}
                  id="file"
                  type="file"
                  className="inputfile"
                />
              </label> */}
                  <Button
                    type="primary"
                    icon={<SendOutlined />}
                    onClick={() => sendMessage(null)}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Card>
      </div>
    );
}