import { message } from "antd";
import Cookies from "js-cookie";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "../container/authProvider";
import { USER } from "../tools/config";
import { headerBearer, post, update } from "../tools/http";
import { getStorage, setStorage } from "../tools/storage";

export default function useProfile() {
    const {token, setAuth} = useAuth();
    const {t} = useTranslation()
    const [user, setUser] = useState(getStorage(USER))
    const [loading, setLoading] = useState(false);
    const [modalPass, setModalPass] = useState(false)
    const [modalCp, setModalCp] = useState(false)

    // modal pass
    const toggleModalpass = () => {
        setModalPass(!modalPass);
    }

    //modal change pass
    const toggleModalChangepass = () => {
        setModalCp(!modalCp);
    }

    const onChangePass = async (values) => {
        try {
            setLoading(true);
            let response = await post('/customer/login', {password: values.password, email: user.email});
            Cookies.set('token', response.token, {expires: 60});
            if (!response.success) throw response;
            response = await post('/customer/changepass', {password: values.newPass, id: user.id}, headerBearer(token));
            if(!response.success) throw response; 
            message.success(t('alerts.updatedpass'))
            setLoading(false);
            return true;
        } catch (err) {
            console.log(err);
            if(err.data.message === "Usuario o contraseña incorrectos") {
                message.error(t('alerts.errorup'));
            } else {
                message.error(t('alerts.errorlogin'));
            }
            setLoading(false);
            return false;
        }
    }

    const onSaveData = async (values) => {
        try {
            setLoading(true);
            const response = await post(`/customer/${user.id}`, values, headerBearer(token));
            if(!response.success) throw response;
            setStorage(USER, response.data);
            setAuth(response.data);
            setUser(response.user);
            message.success(t('alerts.updatedsuccess'));
            setLoading(false);
        } catch (error) {
            message.success(t('alerts.errorsave'));
            setLoading(false);
        }
    }

    return {
        user,
        modalPass,
        toggleModalpass,
        loading,
        onSaveData,
        modalCp,
        toggleModalChangepass,
        onChangePass
    }
}