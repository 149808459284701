export const USER = 'user';

export const httpApi = {
    // baseUrl: 'http://localhost:5000',
    baseUrl: 'https://test.upgrap.com',
    // urlWs: 'ws://localhost:5000/api/v1/messages/ws',
    urlWs: 'wss://test.upgrap.com/api/v1/messages/ws',
    prefix: '/api/v1',
    static: '/public/public/',
    headers: {'Content-Type': 'application/json'}
}